import React from 'react';
import { Box, Flex, Text, Grid, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Container from '../../components/Container';
import SectionTitle from '../../components/SectionTitle';

// images
import icon from '../../images/features/feature-7.svg';

const Barriers = (): JSX.Element => {
  const { t } = useTranslation('barriers');
  return (
    <Box px={{ base: 4, lg: 20 }} mb="96px">
      <Container>
        <Box id="barriers">
          <SectionTitle preTitle={t('preTitle')} title={t('title')} />
          <Grid templateColumns={{ lg: 'repeat(2, 1fr)' }} textAlign="left" gap={6}>
            <Flex mb={16}>
              <Image src={icon} alt="icon" mr="5" maxW={12} />
              <Box>
                <Text fontWeight="500" size="md">
                  {t('barrierTitle1', 'Baja barrera de inversión')}
                </Text>
                <Text size="md">
                  {t(
                    'barrierSubtitle1',
                    'Por primera vez en la historia podes comprar tierra por solo USD 100'
                  )}
                </Text>
              </Box>
            </Flex>
            <Flex mb={16}>
              <Image src={icon} alt="icon" mr="5" maxW={12} />
              <Box>
                <Text fontWeight="500" size="md">
                  {t('barrierTitle2', 'Rentabilidad en Soja')}
                </Text>
                <Text size="md">
                  {t('barrierSubtitle2', 'Cobrá tu arrendamiento en Agrotokens (SOYA)')}
                </Text>
              </Box>
            </Flex>
            <Flex mb={16}>
              <Image src={icon} alt="icon" mr="5" maxW={12} />
              <Box>
                <Text fontWeight="500" size="md">
                  {t('barrierTitle3', 'Mayor liquidez en la venta')}
                </Text>
                <Text size="md">
                  {t(
                    'barrierSubtitle3',
                    'Landtoken abre un mercado de oferta y demanda más amplio'
                  )}
                </Text>
              </Box>
            </Flex>
            <Flex mb={16}>
              <Image src={icon} alt="icon" mr="5" maxW={12} />
              <Box>
                <Text fontWeight="500" size="md">
                  {t('barrierTitle4', 'Manejo de las tierras asegurado')}
                </Text>
                <Text size="md">
                  {t(
                    'barrierSubtitle4',
                    'Te conectamos con los operadores calificados para el negocio'
                  )}
                </Text>
              </Box>
            </Flex>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Barriers;
