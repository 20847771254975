import React from 'react';

import { Box, Grid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import Container from '../../components/Container';
import Feature from '../../components/Feature';
import Form from '../../components/Form';
import SectionTitle from '../../components/SectionTitle';

import feature7 from '../../images/features/feature-7.svg';
import feature8 from '../../images/features/feature-8.svg';
import truck from '../../images/truck.svg';
import eye from '../../images/eye.svg';
import { LandtokenSignUp } from '../../utils/urls';

const Join = (): JSX.Element => {
  const { t } = useTranslation('join');
  const [open, setOpen] = React.useState(false);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          formUrl
        }
      }
    }
  `);
  const { formUrl } = data.site.siteMetadata;

  const want = [
    {
      title: t('want.0.title', '¿Querés vender?'),
      subtitle: t(
        'want.0.subtitle',
        'Landtoken te permite acceder a una mayor liquidez y flexibilidad en la venta de tus tierras.'
      ),
      icon: feature7,
    },
    {
      title: t('want.1.title', '¿Querés ser dueño?'),
      subtitle: t(
        'want.1.subtitle',
        'Con tan solo 100 USD podés comprar una fracción de tierra productiva y cobrar los rendimientos'
      ),
      icon: feature8,
    },
    {
      title: t('want.2.title', '¿Querés operar?'),
      subtitle: t(
        'want.2.subtitle',
        'Todas las tierras necesitan ser operadas, participa con tu propuesta'
      ),
      icon: truck,
    },
    {
      title: t('want.3.title', '¿Sos broker de campos?'),
      subtitle: t('want.3.subtitle', 'Dale más visibilidad y liquidez a tus campos en venta'),
      icon: eye,
    },
  ];

  return (
    <Box id="be-part" px={{ base: 4, lg: 20 }} pt={36} pb={24}>
      <Container>
        <SectionTitle
          preTitle={t('tag', 'Nuevas posibilidades')}
          title={t('join.title', '¿Querés formar parte del ecosistema?')}
          desc={t(
            'join.subtitle',
            'Unimos la tecnología, las finanzas, la criptoeconomía y los negocios agropecuarios, para que todos puedan aprovechar las oportunidades de un mundo más simple y eficiente.'
          )}
        />
        <Grid templateColumns={{ lg: 'repeat(4, 1fr)' }} textAlign="left" gap={6}>
          {want.map((actor, i) => (
            <Feature
              bordered
              key={actor.title}
              title={actor.title}
              subtitle={actor.subtitle}
              icon={actor.icon}
              onClick={{
                link: LandtokenSignUp,
                text: t('register', 'Registrate'),
              }}
              badge={i === 3 ? t('badge', 'Próximamente') : undefined}
            />
          ))}
        </Grid>
        <Form isOpen={open} onClose={() => setOpen(false)} formUrl={formUrl} />
      </Container>
    </Box>
  );
};

export default Join;
