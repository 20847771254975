import React from 'react';

import { Box, Grid, GridItem, Image, Flex, Text, Show, Hide, Divider } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import SectionTitle from '../../components/SectionTitle';
import Container from '../../components/Container';

import img from '../../images/comparison.png';
import check from '../../images/small-check.svg';

type CompItemProps = {
  text: string;
  showCheck?: boolean;
};

const CompItem = ({ text, showCheck }: CompItemProps) => (
  <Box mb={8}>
    <Flex justifyContent="flex-start" alignItems="center">
      <Text fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>{text}</Text>
      {showCheck && <Image src={check} alt="check" ml={2} />}
    </Flex>
  </Box>
);

type MobileCompItemProps = {
  title: string;
  tradTitle: string;
  trad: string;
  tradShowCheck?: boolean;
  tokTitle: string;
  tok: string;
  tokShowCheck?: boolean;
};

const MobileCompItem = ({
  title,
  tradTitle,
  trad,
  tradShowCheck,
  tokTitle,
  tok,
  tokShowCheck,
}: MobileCompItemProps) => (
  <Box>
    <Text mb={4} fontWeight="600" textColor="gold">
      {title}
    </Text>
    <Grid templateColumns="repeat(2, 1fr)" pb={4}>
      <GridItem>
        <Text mb={2} fontWeight="100" textColor="gold">
          {tradTitle}
        </Text>
        <CompItem text={trad} showCheck={tradShowCheck} />
      </GridItem>
      <GridItem>
        <Text mb={2} fontWeight="100" textColor="gold">
          {tokTitle}
        </Text>
        <CompItem text={tok} showCheck={tokShowCheck} />
      </GridItem>
    </Grid>
  </Box>
);

const Comparison = (): JSX.Element => {
  const { t } = useTranslation('comparison');
  return (
    <Box id="why" px={{ base: 4, lg: 20 }} pt={16}>
      <Container>
        <SectionTitle preTitle={t('preTitle')} title={t('title')} />

        {/* Mobile */}
        <Hide above="lg">
          <Box mb={8}>
            <MobileCompItem
              title={t('var0')}
              tradTitle={t('tradLandTitle')}
              trad={t('tradLand0')}
              tokTitle={t('tokenizedLandTitle')}
              tok={t('tokenizedLand0')}
              tokShowCheck
            />
            <MobileCompItem
              title={t('var1')}
              tradTitle={t('tradLandTitle')}
              trad={t('tradLand1')}
              tokTitle={t('tokenizedLandTitle')}
              tok={t('tokenizedLand1')}
              tokShowCheck
            />
            <MobileCompItem
              title={t('var2')}
              tradTitle={t('tradLandTitle')}
              trad={t('tradLand2')}
              tokTitle={t('tokenizedLandTitle')}
              tok={t('tokenizedLand2')}
              tokShowCheck
            />
            <MobileCompItem
              title={t('var3')}
              tradTitle={t('tradLandTitle')}
              trad={t('tradLand3')}
              tradShowCheck
              tokTitle={t('tokenizedLandTitle')}
              tok={t('tokenizedLand3')}
              tokShowCheck
            />
            <MobileCompItem
              title={t('var4')}
              tradTitle={t('tradLandTitle')}
              trad={t('tradLand4')}
              tradShowCheck
              tokTitle={t('tokenizedLandTitle')}
              tok={t('tokenizedLand4')}
              tokShowCheck
            />
            <MobileCompItem
              title={t('var5')}
              tradTitle={t('tradLandTitle')}
              trad={t('tradLand5')}
              tokTitle={t('tokenizedLandTitle')}
              tok={t('tokenizedLand5')}
              tokShowCheck
            />
            <MobileCompItem
              title={t('var6')}
              tradTitle={t('tradLandTitle')}
              trad={t('tradLand6')}
              tokTitle={t('tokenizedLandTitle')}
              tok={t('tokenizedLand6')}
              tokShowCheck
            />
            <MobileCompItem
              title={t('var7')}
              tradTitle={t('tradLandTitle')}
              trad={t('tradLand7')}
              tokTitle={t('tokenizedLandTitle')}
              tok={t('tokenizedLand7')}
              tokShowCheck
            />
          </Box>
        </Hide>

        {/* Desktop */}
        <Show above="lg">
          <Grid templateColumns="repeat(12, 1fr)" pb={24}>
            <GridItem colSpan={3}>
              <Text mb={8} fontWeight="500" textColor="gold">
                {t('varTitle')}
              </Text>
              <CompItem text={t('var0')} />
              <CompItem text={t('var1')} />
              <CompItem text={t('var2')} />
              <CompItem text={t('var3')} />
              <CompItem text={t('var4')} />
              <CompItem text={t('var5')} />
              <CompItem text={t('var6')} />
              <CompItem text={t('var7')} />
            </GridItem>
            <GridItem colSpan={3}>
              <Text mb={8} fontWeight="500" textColor="gold">
                {t('tradLandTitle')}
              </Text>
              <CompItem text={t('tradLand0')} />
              <CompItem text={t('tradLand1')} />
              <CompItem text={t('tradLand2')} />
              <CompItem text={t('tradLand3')} showCheck />
              <CompItem text={t('tradLand4')} showCheck />
              <CompItem text={t('tradLand5')} />
              <CompItem text={t('tradLand6')} />
              <CompItem text={t('tradLand7')} />
            </GridItem>
            <GridItem colSpan={2}>
              <Text mb={8} fontWeight="500" textColor="gold">
                {t('tokenizedLandTitle')}
              </Text>
              <CompItem text={t('tokenizedLand0')} showCheck />
              <CompItem text={t('tokenizedLand1')} showCheck />
              <CompItem text={t('tokenizedLand2')} showCheck />
              <CompItem text={t('tokenizedLand3')} showCheck />
              <CompItem text={t('tokenizedLand4')} showCheck />
              <CompItem text={t('tokenizedLand5')} showCheck />
              <CompItem text={t('tokenizedLand6')} showCheck />
              <CompItem text={t('tokenizedLand7')} showCheck />
            </GridItem>
            <GridItem colSpan={4}>
              <Image src={img} alt="step1" maxH="595px" />
            </GridItem>
          </Grid>
        </Show>
      </Container>
    </Box>
  );
};

export default Comparison;
