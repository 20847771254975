import React from 'react';

import { Box, Heading, Text } from '@chakra-ui/react';

export type SectionTitleProps = {
  preTitle: string;
  title: string;
  desc?: string;
};

const SectionTitle = ({ preTitle, title, desc }: SectionTitleProps): JSX.Element => {
  return (
    <Box w={{ lg: '768px' }} mb="64px">
      <Text mb={3} fontWeight="500" textColor="gold">
        {preTitle}
      </Text>
      <Heading size={{ base: 'sm', lg: 'md' }} mb={5}>
        {title}
      </Heading>
      {desc && (
        <Text size={{ base: 'lg', lg: 'xl' }} mb={{ base: 8, lg: 16 }}>
          {desc}
        </Text>
      )}
    </Box>
  );
};

export default SectionTitle;
