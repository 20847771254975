import { Box, Button, Flex, Heading, Link, Image, Show, Text, Hide } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import Container from '../../components/Container';
import Form from '../../components/Form';

import landing from '../../images/landing.png';
import partnersAgrotoken from '../../images/companies/agrotoken.svg';
import partnersAdecoagro from '../../images/companies/adecoagro.png';
import { LandtokenSignUp } from '../../utils/urls';
import media from './data';

const Landing = (): JSX.Element => {
  const { t } = useTranslation('landing');
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          formUrl
        }
      }
    }
  `);
  const { formUrl } = data.site.siteMetadata;

  return (
    <Box mx={{ base: 4, lg: 20 }} mt={{ base: 8, lg: 20 }} mb={{ base: 8, lg: 10 }}>
      <Container>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justify="space-between"
          alignItems="flex-start"
        >
          <Box w={{ lg: '55%' }}>
            <Hide above="lg">
              <Heading as="h1" size="md" mb={1.5} w={{ lg: '100%' }}>
                {t('heading-mobile', 'El valor de la tierra, accesible para todos.')}
              </Heading>
            </Hide>
            <Show above="lg">
              <Heading as="h1" size="xl" mb={8} w={{ lg: '100%' }}>
                {t('heading', 'El valor de la tierra,\n accesible para todos.')
                  .split('\n')
                  .map((item) => (
                    <Text key={item}>{item}</Text>
                  ))}
              </Heading>
            </Show>
            <Text size="xl" mb={8} fontWeight="400" w={{ lg: '80%' }}>
              {t(
                'description',
                'Ahora podés ser dueño de un campo sin importar el monto a invertir, accediendo a la seguridad y rentabilidad del negocio agrícola.'
              )}
            </Text>

            <Link href={LandtokenSignUp} target="_blank">
              <Button
                variant="secondary"
                h={{ base: 12, lg: '60px' }}
                w={{ base: '100%', sm: 'auto' }}
                marginBottom={{ base: 2, sm: 0 }}
                marginRight="4"
              >
                {t('sign-up', 'Registrate')}
              </Button>
            </Link>

            <Button
              variant="primary"
              h={{ base: 12, lg: '60px' }}
              w={{ base: '100%', sm: 'auto' }}
              onClick={toggleModal}
            >
              {t('button', 'Quiero saber más')}
            </Button>

            <Form isOpen={isOpen} onClose={toggleModal} formUrl={formUrl} />
            <Box py={4}>
              <Text size="md">{t('price', 'Podes comprar Landtoken desde USD 100')}</Text>
              <Flex direction="column" maxW="395px" mt={{ base: 16, lg: 24 }}>
                <Text size="md" mb={{ base: 3 }}>
                  {t('partners', 'Nos acompañan')}
                </Text>
                <Flex h={{ lg: '35px' }} align="center" wrap="wrap" gap={6}>
                  <Image maxW="144px" src={partnersAgrotoken} alt="agrotoken" />
                  <Image maxW="144px" src={partnersAdecoagro} alt="adecoagro" />
                  <Link href="/team">
                    <Text color="gray.700" decoration="underline">
                      {t('see-more', 'Ver más')}
                    </Text>
                  </Link>
                </Flex>
              </Flex>
              <Flex direction="column" my={{ base: 5 }} mt={{ base: '45px' }}>
                <Text size="md" mb={{ base: 3 }}>
                  {t('media')}
                </Text>
                <Flex h={{ lg: '35px' }} align="center" wrap="wrap" gap={{ base: 4, sm: 8 }}>
                  {media.map((item) => (
                    <Link
                      href={item.link}
                      target="_blank"
                      key={item.name}
                      w={{ base: '150px', lg: 'auto' }}
                    >
                      <Image
                        maxH={item.width}
                        minH={item.width}
                        src={item.image}
                        alt="bichosdecampo"
                        __css={{ filter: 'grayscale(100%)' }}
                        _hover={{ filter: 'grayscale(0%)' }}
                      />
                    </Link>
                  ))}
                </Flex>
              </Flex>
            </Box>
          </Box>
          <Hide below="lg">
            <Image position="relative" w="550px" src={landing} alt="landtoken" />
          </Hide>
        </Flex>
      </Container>
    </Box>
  );
};

export default Landing;
