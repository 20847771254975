import React from 'react';

import { Box, Flex, Text, Button, Link, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import SectionTitle from '../../components/SectionTitle';
import Container from '../../components/Container';
import { LandtokenProjects } from '../../utils/urls';

import Cards from '../../images/offers/cards.png';

const LatestOffers = (): JSX.Element => {
  const { t } = useTranslation('latestOffers');

  return (
    <Box id="invest-in-land" px={{ base: 4, lg: 20 }} pt={16}>
      <Container>
        <SectionTitle preTitle={t('preTitle')} title={t('title')} />
        <Flex
          gap={{ base: 50 }}
          wrap={{ base: 'wrap', md: 'nowrap' }}
          mb="65px"
          justifyContent={{ base: 'center', md: 'flex-start' }}
        >
          <Image src={Cards} top="25" height={{ base: 'auto', sm: '435px' } } padding={{ base: '0 15px 0 15px', sm: 0 }} />
 
          <Flex direction="column" gap={8} order={{ base: 1, sm: 2 }} mt={{ base: 0, md: '15px'}} pr={{ base: 0, md: '200px'}}>
            <Box>
              <Text size="xl" mb={3} color="gray.900">
              Campo Don Pedro
              </Text>
              <Text size="md" mb={3}>
              Luego de nuestro primer campo, vendido completamente en tan solo 60 días, lanzamos un nuevo campo en oferta.
              </Text>
              <Text size="md" mb={3}>
              El campo Don Pedro, ubicado en la zona núcleo agrícola del país, posee una superficie total de 141 hectáreas y mantiene la inversión mínima de USD 100.
              </Text>
              <Text size="md" mb={3}>Invertí en pesos o en dólares y participá de la compra de un activo dolarizado, con un flujo de renta en la misma moneda.</Text>
              <Text size="md" mb={3}>Además, en la sección Campos podés hacer tu oferta para invertir en nuestros campos anteriores.</Text>
            </Box>
            <Flex gap={{ base: 5, md: 70 }} justifyContent={"flex-end"}>
              <Link href={LandtokenProjects} target="_blank">
                <Button
                  variant="secondary"
                  h={{ base: 12, lg: '60px' }}
                  w={{ base: '100%', sm: 'auto' }}
                  marginBottom={{ base: 2, sm: 0 }}>
                  {t('see_projects', 'Ver campos')}
                </Button>
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default LatestOffers;
