import React from 'react';
import { Box, Heading, Text, Image, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';

import step1 from '../../images/landtokens/step1.png';
import step2 from '../../images/landtokens/step2.png';
import step3 from '../../images/landtokens/step3.svg';

type StepProps = {
  icon: string;
  title: string;
  subtitle: string;
};

const Step = ({ icon, title, subtitle }: StepProps) => (
  <Box>
    <Image src={icon} alt="step1" mb={8} />
    <Text size="lg" fontWeight="500">
      {title}
    </Text>
    <Text size="md">{subtitle}</Text>
  </Box>
);

const landtokens = () => {
  const { t } = useTranslation('landtokens');

  return (
    <Box px={{ base: 4, lg: 20 }} py={{ base: 16, lg: 24 }} textAlign="center">
      <Container>
        <Text mb={3} fontWeight="500" textColor="gold">
          {t('landtoken-title', '3 pasos')}
        </Text>
        <Heading size="md" fontWeight="500" mb={20}>
          {t('landtoken-desc', 'Landtoken es simple')}
        </Heading>
        <SimpleGrid spacing={20} alignItems="flex-start" columns={{sm: 1, md: 3}}>
          <Step
            icon={step1}
            title={t('step-1', 'Registrate en Landtoken')}
            subtitle={t(
              'step-1-sub',
              'Registrarse en Landtoken es super fácil, podés hacerlo en tan solo 4 clicks.'
            )}
          />
          <Step
            icon={step2}
            title={t('step-2', 'Comprá tu héctarea')}
            subtitle={t(
              'step-2-sub',
              'Seleccioná el campo que más te guste, cargá fondos a tu wallet y comprá.'
            )}
          />
          <Step
            icon={step3}
            title={t('step-3', 'Cobrá tu renta')}
            subtitle={t(
              'step-3-sub',
              'Recibí tu arrendamiento en Agrotokens SOYA y accedé a todos los usos y beneficios de Agrotoken.'
            )}
          />
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default landtokens;
