import React from 'react';

import { Badge, Box, Link, Flex, Image, Text } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

type Props = {
  icon: string;
  title: string;
  subtitle: string;
  bordered?: boolean;
  centered?: boolean;
  badge?: string;
  onClick?: {
    link: string;
    text: string;
  };
};

const Feature = ({
  icon,
  title,
  subtitle,
  bordered,
  centered,
  onClick,
  badge,
}: Props): JSX.Element => {
  let border = {};
  if (bordered) {
    border = {
      border: '1px',
      borderRadius: '3px',
      borderColor: 'borders.200',
    };
  }

  return (
    <Flex p={bordered ? 5 : 0} {...border} w="100%" direction="column" justify="space-between">
      <Box>
        <Image src={icon} alt={title} mb={4} mx={{ lg: centered ? 'auto' : '' }} />
        <Box pl={1}>
          <Text size="xl" mb={2} color="gray.900" fontWeight="500">
            {title}
          </Text>
          <Text size="md" color="gray.700">
            {subtitle}
          </Text>
        </Box>
      </Box>
      {onClick && !badge && (
        <Flex align="center" mt={9}>
          <Link href={onClick.link} target="_blank">
            <Text color="#925632" fontWeight="500">
              {onClick.text}
            </Text>
          </Link>
          <ArrowForwardIcon color="#925632" ml={3} />
        </Flex>
      )}
      {badge && (
        <Flex align="center" mt={9}>
          <Badge alignSelf="flex-start" colorScheme="gold">
            {badge}
          </Badge>
        </Flex>
      )}
    </Flex>
  );
};

export default Feature;
