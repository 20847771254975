import React from 'react';

import { Box, Flex, Image, Text } from '@chakra-ui/react';

type FeatureContentProps = {
    icon: string;
    title: string;
    desc: string;
    centered?: boolean;
  };
  
  const FeatureContent = ({ icon, title, desc, centered }: FeatureContentProps): JSX.Element => {
    return (
      <Flex w="100%" direction="column" justify="space-between">
        <Box>
          <Image src={icon} alt={title} mb={4} mx={{ lg: centered ? 'auto' : '' }} />
          <Box pl={1}>
            <Text size="xl" mb={2} color="gray.900">
              {title}
            </Text>
            <Text size="md" color="gray.700">
              {desc}
            </Text>
          </Box>
        </Box>
      </Flex>
    );
  };

  export default FeatureContent;