import React from 'react';

import { Box, Image, Text, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import whatsapp from '../../images/whatsapp.svg';

const WhatsappChat = (): JSX.Element => {
  const { t } = useTranslation('whatsapp');
  return (
    <Link href={t('link')} target="_blank">
      <Box
        display="flex"
        alignItems="center"
        alignContent="stretch"
        backgroundColor="white"
        borderRadius="6px"
        bottom="24px"
        boxShadow="0px 0px 16px -4px rgba(16, 24, 40, 0.1)"
        gap="4px"
        padding="14px 18px"
        position="fixed"
        right="30px"
        width="fit-content"
        animation="fadeInRight 2.5s"
        cursor="pointer"
        _hover={{
          backgroundColor: '#fbf8ec',
        }}
      >
        <Box display="flex" flexDirection="row" gap="12px">
          <Image src={whatsapp} alt="Whatsapp" />
          <Box display="flex" flexDirection="column" justifyContent="space-around">
            <Text fontSize="sm" fontWeight={500}>
              {t('question')}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {t('available_in')}
            </Text>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default WhatsappChat;
