import React from 'react';
import { Flex, Box, Heading, Text, Image, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Container from '../../components/Container';

import highlight1 from '../../images/highlights/enter-up.svg';
import highlight2 from '../../images/highlights/check.svg';
import highlight3 from '../../images/highlights/enter-side.svg';
import highlight4 from '../../images/features/feature-9.svg';

type HighlightProps = {
  icon: string;
  title: string;
};

const Highlight = ({ icon, title }: HighlightProps) => (
  <Box textAlign="center" mb={16}>
    <Image src={icon} alt={title} mb={6} marginX="auto" />
    <Text size={{ base: 'sm', lg: 'lg' }} fontWeight="500">
      {title}
    </Text>
  </Box>
);

const MeetLandtoken = () => {
  const { t } = useTranslation('meetLandtoken');

  return (
    <Box id="we-do" px={{ base: 4, lg: 20 }} py={24} textAlign="center">
      <Container>
        <Box mb={24}>
          <Heading size="md" fontWeight="500" mb={6}>
            {t('title')}
          </Heading>
          <Text size={{ base: 'lg', lg: 'xl' }} maxW="920px" margin="0 auto 24px auto">
            {t('desc1')}
          </Text>
          <Text
            size={{ base: 'lg', lg: 'xl' }}
            mb={{ base: 8, lg: 16 }}
            maxW="920px"
            margin="0 auto"
          >
            {t('desc2')}
          </Text>
        </Box>
        <SimpleGrid columns={{ base: 2, md: 4 }} maxW="4xl" mx="auto">
          <Highlight icon={highlight1} title={t('highlight1')} />
          <Highlight icon={highlight2} title={t('highlight2')} />
          <Highlight icon={highlight3} title={t('highlight3')} />
          <Highlight icon={highlight4} title={t('highlight4')} />
        </SimpleGrid>
      </Container>

      <Box maxW="3xl" mx="auto">
        <Box style={{ paddingBottom: '56.25%', position: 'relative', height: '100%' }}>
          <iframe
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              borderRadius: '3px',
            }}
            src="https://www.youtube.com/embed/f88gpa3suHI"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MeetLandtoken;
