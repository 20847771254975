/* eslint-disable import/prefer-default-export */
import Cookies from 'js-cookie';

const reorderCodes = (codes: string, newCode: string) => {
  const arrayTags = codes?.split(', ').filter(Boolean);
  const index = arrayTags?.indexOf(newCode);

  if (index !== -1) {
    arrayTags?.splice(index, 1);
  }

  arrayTags?.unshift(newCode);

  if (arrayTags?.length === 1) {
    return newCode;
  }

  return arrayTags?.join(', ');
};

export const setCookie = (cookieName: string, days: number = 15) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const cookie = urlParams.get(cookieName);

  if (!cookie) return;

  const referralCodes = Cookies.get(cookieName) ?? '';
  Cookies.set(cookieName, reorderCodes(referralCodes, cookie), { expires: days });
};
