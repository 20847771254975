import React from 'react';

import { Box, Grid, Divider } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import SectionTitle from '../../components/SectionTitle';
import Container from '../../components/Container';
import FeatureContent from '../../components/FeatureContent';

import feature8 from '../../images/features/feature-8.svg';
import feature9 from '../../images/features/feature-9.svg';
import feature11 from '../../images/features/feature-11.svg';

const InvestInLand = (): JSX.Element => {
  const { t } = useTranslation('investInLand');
  return (
    <Box id="invest-in-land" px={{ base: 4, lg: 20 }} pt={36}>
      <Container>
        <SectionTitle preTitle={t('preTitle')} title={t('title')} />
        <Grid templateColumns={{ lg: 'repeat(3, 1fr)' }} textAlign="left" gap={6}>
          <FeatureContent
            icon={feature11}
            title={t('reasonTitle1')}
            desc={t('reasonDescription1')}
          />
          <FeatureContent
            icon={feature8}
            title={t('reasonTitle2')}
            desc={t('reasonDescription2')}
          />
          <FeatureContent
            icon={feature9}
            title={t('reasonTitle3')}
            desc={t('reasonDescription3')}
          />
        </Grid>
        <Divider my="96px" />
      </Container>
    </Box>
  );
};

export default InvestInLand;
