import React, { useEffect } from 'react';
import { Box, ChakraProvider, Flex } from '@chakra-ui/react';
import { graphql } from 'gatsby';
// import Cookies from 'js-cookie';
import theme from '../utils/theme';

import Header from '../components/Header';
import Landing from '../Sections/Landing';
import InvestInLand from '../Sections/InvestInLand';
import Barriers from '../Sections/Barriers';
import MeetLandtoken from '../Sections/MeetLandtoken';
import Comparison from '../Sections/Comparison';
import Landtokens from '../Sections/Landtokens/Landtokens';
import Join from '../Sections/Join/Join';
import Banner from '../Sections/Banner';
import Footer from '../components/Footer';
import Seo from '../components/SEO';
import HelpCenter from '../Sections/HelpCenter';
import { setCookie } from '../utils/set-cookies';
import WhatsappChat from '../components/WhatsappChat';
import LatestOffers from '../Sections/LatestOffers';

const IndexPage = () => {
  useEffect(() => {
    setCookie('camp');
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line global-require
      require('smooth-scroll')('a[href*="#"]', {
        updateURL: false,
        offset: 48,
      });
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Flex direction="column">
        <Header />
        <Box background="linear-gradient(85.3deg, #FBFAF6 17.89%, #FFFFFF 84%);">
          <Landing />
        </Box>
        <Box background="radial-gradient(92.39% 92.39% at 10.73% 7.61%, #FCFBF8 0%, rgba(255, 255, 255, 0) 51.04%, #F6F5F1 100%);">
          <LatestOffers />
        </Box>
        <Box background="radial-gradient(92.39% 92.39% at 10.73% 7.61%, #FCFBF8 0%, rgba(255, 255, 255, 0) 51.04%, #F6F5F1 100%);">
          <InvestInLand />
          <Barriers />
        </Box>
        <MeetLandtoken />
        <Box background="linear-gradient(180deg, #FFFFFF 0%, #F6F5F1 100%, rgba(255, 255, 255, 0) 100%);">
          <Comparison />
        </Box>
        <Landtokens />
        <Box background="linear-gradient(85.3deg, #FCFBF8 17.89%, #FFFFFF 84%);">
          <Join />
        </Box>
        <HelpCenter />
        <Banner />
      </Flex>
      <Footer />
      <WhatsappChat />
    </ChakraProvider>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default IndexPage;

export const Head = () => <Seo />;
