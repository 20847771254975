import bichosdecampo from '../../images/companies/bichosdecampo.png';
import ambito from '../../images/companies/ambito.svg';
import neura from '../../images/companies/neura.png';
import newsweek from '../../images/companies/newsweek.png';

const media = [
  {
    name: 'newsweek',
    link: 'https://www.newsweek.com.ar/economia-negocios/matias-simone-con-landtoken-cualquiera-puede-participar-en-la-compra-de-un-campo-y-obtener-renta/',
    image: newsweek,
    width: '23px',
  },
  {
    name: 'ambito',
    link: 'https://www.ambito.com/negocios/landtoken-un-nuevo-hito-las-inversiones-tierra-productiva-n6012813',
    image: ambito,
    width: '23px',
  },
  {
    name: 'bichosdecampo',
    link: 'https://bichosdecampo.com/el-sueno-del-pibe-una-plataforma-propone-que-con-solo-100-dolares-seas-propietario-de-un-pedacito-de-campo-y-ganes-dinero-alquilandolo/',
    image: bichosdecampo,
    width: '50px',
  },
  {
    name: 'neura',
    link: 'https://www.youtube.com/watch?v=t1btFNoh1So&t=4175s&ab_channel=NeuraMedia',
    image: neura,
    width: '40px',
  },
  // {
  //   name: 'infocampo',
  //   link: 'https://www.infocampo.com.ar/las-inversiones-del-campo-al-alcance-de-un-clic-como-invertir-en-tierras-con-un-token/',
  //   image: infocampo,
  //   width: '30px',
  // }
  // {
  //   name: 'argentinapotencia',
  //   link: 'https://www.youtube.com/watch?v=sy7Qa4AweN8&ab_channel=LucianoMandli',
  //   image: argentinapotencia,
  //   width: '37px',
  // },
  // {
  //   name: 'cryptoconexion',
  //   link: 'https://cryptoconexion.com/landtoken-venden-campos-agricolas-tokenizados-argentina/',
  //   image: cryptoconexion,
  //   width: '105px',
  // },
];

export default media;
